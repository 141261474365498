export function Show({
  children,
  show,
}: {
  show: boolean;
  children: React.ReactNode;
}) {
  if (!show) return null;
  return children;
}
